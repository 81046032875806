/** @format */

import React, {useState} from "react";
import PropTypes from "prop-types";
import {navigate} from "gatsby";
import {
  Button,
  FormControl,
  FormHelperText,
  Input,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import SEO from "../components/seo";

const Contact = () => {
  const [state, setState] = useState({});

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleChange = (e) => {
    setState({...state, [e.target.name]: e.target.value});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch("/", {
      method: "POST",
      headers: {"Content-Type": "application/x-www-form-urlencoded"},
      body: encode({
        "form-name": event.target.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => {
        navigate("/thanks/");
      })
      .catch((error) => alert(error));
  };

  return (
    <form
      id="my-form"
      name="contact-form"
      method="post"
      action="/thanks/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <FormControl isRequired>
        <Input type="hidden" name="form-name" value="contact-form" />

        <VStack spacing={5} py={8} my={5}>
          <Input name="name" placeholder="Name" onChange={handleChange} />
          <Input
            name="email"
            type="email"
            placeholder="Email"
            onChange={handleChange}
          />
          <Input
            name="time"
            placeholder="Suggest a time and day"
            onChange={handleChange}
          />
          <FormHelperText>Well never share your email.</FormHelperText>
          <Textarea
            name="message"
            placeholder="Type your message here"
            onChange={handleChange}
          />
          <Button variant="contact" type="submit" form="my-form">
            Send
          </Button>
        </VStack>
      </FormControl>
    </form>
  );
};

Contact.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Contact;

export const Head = () => <SEO title="Aphro Tech Consult - Contact" />;
